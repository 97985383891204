import { IsNode } from "../../reactor/AssertNode"
import React, { createContext, useContext, useEffect, useState } from "react"
import { SSRHook } from "./SSRHook"
import { AuthConfig } from "../../reactor/Model"
import { Route, useParams } from "react-router-dom"
import { whenReflectionReady } from "../../studio/reflection-client"
import { Uuid } from "../../reactor/Types/Primitives"
import { ProblemJson } from "./ProblemJson"

// Injected by SSR
declare let authConfig: AuthConfig | undefined

const LoginContext = createContext({ isLoggedIn: false })
export function useIsLoggedIn() {
    return useContext(LoginContext).isLoggedIn
}

function parseJwt(token: string) {
    try {
        const base64Url = token.split(".")[1]
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
        const jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
                })
                .join("")
        )

        return JSON.parse(jsonPayload)
    } catch {
        return {}
    }
}

/** If we got an error from the auth callback, it is set here so it is
 * immediately visible to the error handler below, so we avoid infinite retry
 * loops. */
let callbackError: string | undefined
let clearTokens: (() => void) | undefined

export function Login(props: {
    always: boolean
    /**
     * The redirect URL after a sucessful login.
     *
     * This must be one of the whitelisted URLs in the OAuth provider. The
     * actual target URL of the redirect will be stored in local storage. The
     * page at this URL should read the target URL from local storage and
     * redirect to it.
     *
     * If unspecified, the user redirected to the root URL.
     */
    returnTo?: string
    tokenReady: (token: string) => void
    /**
     * A function that handles login attempts. If unspecified, the default login
     * function is used (the one configured for Fuse Studio).
     */
    login?: (
        /**
         * The default login function, if you want to use it.
         */
        defaultLogin: (returnTo?: string) => void,
        /**
         * The URL to return to after logging in.
         */
        returnTo?: string,
        /** The Unauthroized error that motivated the login attempt, if any.
         *
         *  Inspecting the error can help the login handler decide what login
         *  method to use, in case of multiple login methods.
         */
        error?: ProblemJson & { status: 401 }
    ) => Promise<void>
    /**
     * Called when the user logs out. This should clear any stored tokens in the
     * API client's default headers.
     */
    clearTokens: () => void
    errorDisplay?: (error: string) => JSX.Element
    children: React.ReactNode
}) {
    const [expiry, setExpiry] = useState<number | undefined>(undefined)
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    useLogin(props.always, (token) => {
        props.tokenReady(token)
        const exp = parseJwt(token).exp
        if (expiry !== exp) setExpiry(exp)
        if (isLoggedIn === false) setIsLoggedIn(true)
    })

    clearTokens = props.clearTokens

    useEffect(() => {
        const handler = async (e: ProblemJson) => {
            // If we got a low-level error from the auth callback - don't handle
            // it. This is e.g. unverified email address.
            if (callbackError) return { handled: false }

            if (e.status === 401) {
                if (expiry) {
                    if (expiry < Date.now() / 1000) {
                        if (await hiddenTokenRefresh()) {
                            return { handled: true, retry: true }
                        } else {
                            alert(
                                "Your login session has expired and cannot be restored.\n\nClick OK to log in again."
                            )
                        }
                    }
                }

                setExpiry(undefined)
                setIsLoggedIn(false)

                if (props.login) {
                    await props.login(
                        login,
                        props.returnTo ?? "/",
                        e as ProblemJson & { status: 401 }
                    )
                } else {
                    login(props.returnTo ?? "/")
                }
                return { handled: true }
            }
            return { handled: false }
        }
        /** Catch all 401's and send us to the login page */
        SSRHook.errorHandlers.push(handler)
        return () => {
            SSRHook.errorHandlers.splice(SSRHook.errorHandlers.indexOf(handler), 1)
        }
    }, [expiry, props.returnTo])

    if (callbackError) {
        if (props.errorDisplay) return props.errorDisplay(callbackError)
        return (
            <div style={{ padding: 32 }}>
                <h1>Authentication error</h1>
                <p>{callbackError}</p>
            </div>
        )
    }

    return <LoginContext.Provider value={{ isLoggedIn }}>{props.children}</LoginContext.Provider>
}

let _tokenReady: (access_token: string) => void | undefined

/** When used in a component, it indicates that the component and its subtree may be depedent on
 *  login to function.
 *
 *  The hook stores the access token in local storage, so a session can persist across page reloads.
 */
function useLogin(
    /** Whether to always log in eagerly, or only log in when a 401 is detected. */
    always: boolean,
    /** Callback you receive when a new access token is available.
     *  This would be a good place to set the `Authorization` header on your API calls, e.g.
     * ```
     *  import { defaults } from "./client" // The client generated by Reactor
     *
     *  defaults.headers["Authorization"] = `Bearer ${access_token}`
     * ```
     */
    tokenReady: (access_token: string) => void
) {
    if (IsNode()) throw new Error("useLogin should not be called on the server")

    _tokenReady = tokenReady

    try {
        const { access_token, state } = getAuthCallback()

        if (access_token) {
            tokenReady(access_token)
            localStorage.setItem("Authorization", access_token)

            // If we are a hidden child iframe, send the hash to the parent window
            if (window.parent !== window) {
                window.parent.postMessage({ type: "hash", hash: window.location.hash }, "*")
            }

            consumeHash(state)
        }

        const token = localStorage.getItem("Authorization")
        if (token) {
            const { exp } = parseJwt(token)
            if (exp > Date.now() / 1000) {
                tokenReady(token)
                return
            } else if (!exp) {
                // We probably have a demo auth token, or other token that doesn't expire
                // so we'll just use it. If it fails, we will get a 401 and log in again.
                tokenReady(token)
                return
            } else {
                localStorage.removeItem("Authorization")
            }
        }

        if (always) login()
    } catch (e: any) {
        if (e instanceof Error) {
            callbackError = e.message
        }
    }
}

function consumeHash(state?: string) {
    if (!state) return

    const authCallback = localStorage.getItem("authState")
    if (!authCallback) throw new Error("No auth callback state")
    const { key, returnTo } = JSON.parse(authCallback)
    if (key !== state) throw new Error("Invalid auth callback state")

    window.location.replace(window.location.origin + (returnTo ?? window.location.pathname))
}

function getAuthCallback(): ReturnType<typeof parseHash> {
    if (IsNode()) {
        return {} as any
    }
    return parseHash(window.location.hash)
}

function parseHash(hash: string) {
    const hashkeys = hash
        .slice(1)
        .split("&")
        .map((x) => x.split("="))
        .map((x) => ({ key: x[0], value: x[1] }))

    if (hashkeys.find((x) => x.key === "error")) {
        const errorDescription = hashkeys.find((x) => x.key === "error_description")?.value
        throw new Error(errorDescription ? decodeURIComponent(errorDescription) : "Unknown error")
    }

    const id_token = hashkeys.find((x) => x.key === "id_token")?.value
    if (id_token) {
        const { nonce } = parseJwt(id_token)
        const id = localStorage.getItem("authNonce")
        if (nonce !== id) throw new Error("Invalid id_token nonce")
        localStorage.setItem("id_token", id_token)
    }

    return {
        access_token: hashkeys.find((x) => x.key === "access_token")?.value,
        id_token,
        scope: hashkeys.find((x) => x.key === "scope")?.value,
        expires_in: hashkeys.find((x) => x.key === "expires_in")?.value,
        token_type: hashkeys.find((x) => x.key === "token_type")?.value,
        state: hashkeys.find((x) => x.key === "state")?.value,
    }
}

function whenAuthReady(callback: (auth: AuthConfig) => void) {
    // In the SSR happy path, the authConfig should be synchronously available
    const auth = typeof authConfig !== "undefined" ? authConfig : undefined
    if (auth) callback(auth)
    // Otherwise, we need to wait for the reflection to be ready to get the auth
    // config from there
    else
        whenReflectionReady((ref) => {
            authConfig = ref.model.authConfig
            callback(authConfig)
        })
}

/** Logs in using the current auth config. */
export function login(returnTo = "/") {
    whenAuthReady((auth) => {
        const url = makeAuthUrl(auth, returnTo)
        window.location.replace(url)
    })
}

/** Logs out using the current auth config. */
export function logout(returnTo: string) {
    whenAuthReady((auth) => {
        const id_token = localStorage.getItem("id_token")
        localStorage.removeItem("id_token")
        localStorage.removeItem("Authorization")

        if (clearTokens !== undefined) clearTokens()

        if (auth === "studio-auth") {
            window.location.replace("/")
            return
        }

        const endSessionEndpoint = auth.endSessionEndpoint
            ? auth.endSessionEndpoint
            : `https://${auth.domain}/v2/logout`
        const url = `${endSessionEndpoint}?${[
            `client_id=${auth.clientId}`,
            `id_token_hint=${id_token}`,
            `post_logout_redirect_uri=${encodeURIComponent(window.location.origin + returnTo)}`,
        ].join("&")}`

        window.location.replace(url)
    })
}

function hiddenTokenRefresh(): Promise<boolean> {
    return new Promise((resolve) => {
        let resolved = false
        const iframe = document.createElement("iframe")

        function listener(event: MessageEvent) {
            if (event.data) {
                try {
                    if (event.data.type === "hash") {
                        const { access_token } = parseHash(event.data.hash)
                        if (access_token) {
                            _tokenReady(access_token)
                            localStorage.setItem("Authorization", access_token)
                            window.removeEventListener("message", listener)
                            if (!resolved) {
                                resolved = true
                                document.body.removeChild(iframe)
                                resolve(true)
                            }
                            return
                        }
                    }
                } catch (e) {}
            }
        }

        window.addEventListener("message", listener)

        whenAuthReady((auth) => {
            iframe.setAttribute("src", makeAuthUrl(auth, "/"))
            iframe.style.display = "hidden"
            document.body.appendChild(iframe)

            // If we don't recevive a callback in reasonable time, assume the refresh failed
            setTimeout(() => {
                if (!resolved) {
                    document.body.removeChild(iframe)
                    resolved = true
                    resolve(false)
                }
            }, 10000)
        })
    })
}

function makeAuthUrl(auth: AuthConfig, returnTo = "/") {
    if (auth === "studio-auth") {
        return "/login?redirect=" + encodeURIComponent(window.location.pathname)
    }

    const key = Uuid()
    localStorage.setItem("authState", JSON.stringify({ key, returnTo: window.location.pathname }))
    const nonce = Uuid()
    localStorage.setItem("authNonce", nonce.valueOf())

    const authorizationEndpoint = auth.authorizationEndpoint
        ? auth.authorizationEndpoint
        : `https://${auth.domain}/authorize`

    return `${authorizationEndpoint}?${[
        `response_type=id_token token`,
        `client_id=${auth.clientId}`,
        `redirect_uri=${encodeURIComponent(window.location.origin + returnTo)}`,
        `scope=openid email`,
        `state=${key}`,
        `audience=${encodeURIComponent(auth.audience)}`,
        `nonce=${nonce}`,
    ].join("&")}`
}

/** A function that produces a login link route.
 *
 * If inserted in a `<Routes>` tag, the result of this function exposes the
 * `/${path}/:token` route which grabs the token from the path and injects it
 * into the authorization headers and keeps it in local storage until logged out.
 *
 * Note that this must be used as a function, not a component, example:
 *
 * ```tsx
 * <Routes>
        <Route path="/">
            <Route index element={<FeedbackUtilityHome />} />
              <Route path="modules" element={<Home />}>
                    <Route index element={<ModulePage />} />
                    <Route path="dashboard" element={<DashboardPage />} />
                    <Route path=":id">
                    <Route path="action-cards" element={<ActionCardsPage />} />
                </Route>
            </Route>
            {LoginLinkRoute({ path: "demo-auth", redirectUrl: "/modules/dashboard" })}
        </Route>
   </Routes>
   ```
 */
export function LoginLinkRoute(props: {
    /** The path to mount the route on, e.g. `"demo-auth"` */
    path: string

    /** Where to direct the user after the token has been stored */
    redirectUrl: string
}) {
    return (
        <Route
            path={`${props.path}/:token`}
            element={<LoginLinkPage redirectUrl={props.redirectUrl} />}
        />
    )
}

function LoginLinkPage(props: { redirectUrl: string }) {
    const token = useParams().token
    if (!token) return <div>Invalid token</div>

    if (!IsNode()) {
        localStorage.setItem("Authorization", token)
        _tokenReady(token)
        window.location.pathname = props.redirectUrl
    }

    return <div>Authenticating...</div>
}
