import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { EditableText } from "../../../packages/editing/EditableText"
import { useHover } from "../../../packages/hooks/useHover"
import { useParam } from "../../../packages/ssr/useParam"
import { OrderByMode, OrderBy } from "../../../reactor/OrderBy"
import { ImageToCSS } from "../../../reactor/Types/File"
import { useFund, GetFundDto, GetFundSolutionsDto, GetFundProspectsPresentedDto } from "./client"
import { Footer } from "./sections/Footer"
import { Slide } from "./Slide"
import { useSolutionPopup, SolutionPopup } from "./SolutionPopup"
import { fontSizes, colors, Title } from "./styles"
import { HeaderSection } from "./sections/Header"

export function FundPage() {
    const slug = useParam("slug")
    const { data: vertical } = useFund(slug)
    if (!vertical) return <></>
    const v = vertical

    return (
        <Slide>
            <HeaderSection
                {...{
                    get image() {
                        return v.image
                    },
                    set image(image) {
                        v.image = image
                    },
                    size: "full",
                    get title() {
                        return v.name
                    },
                    set title(title: string) {
                        v.name = title
                    },
                }}
            />
            <div className="container" style={{ marginTop: 64 }}>
                <div className="row" style={{ flexWrap: "wrap", marginTop: 32 }}>
                    <InfoText vertical={vertical} />
                    <InfoBox vertical={vertical} />
                </div>

                <SolutionsList
                    title={`The ${vertical.name} Solutions (${vertical.solutions.length})`}
                    fundName={vertical.name}
                    solutions={v.solutions}
                    isInPortfolio={vertical.isInPortfolio}
                    isAuthenticated={v.isAuthenticated}
                />
                <SolutionsList
                    title={`Prospect Solutions (${vertical.prospectsPresented.length})`}
                    fundName={vertical.name}
                    solutions={v.prospectsPresented}
                    isInPortfolio={false}
                    isAuthenticated={v.isAuthenticated}
                />
            </div>
            <Footer />
        </Slide>
    )
}
const twoDecimals = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
})
const thousandsSeparated = new Intl.NumberFormat("en-US", {
    useGrouping: true,
    maximumFractionDigits: 0,
})

function InfoText({ vertical }: { vertical: GetFundDto }) {
    return (
        <div className="col-lg">
            <EditableText
                obj={vertical}
                prop="name"
                style={{
                    fontSize: fontSizes.header,
                    fontWeight: "bold",
                    color: colors.darkGreen,
                    marginBottom: "1rem",
                }}
            />
            <EditableText
                obj={vertical}
                isMarkdown={true}
                prop="summary"
                style={{ marginBottom: "2rem", fontSize: 20, maxWidth: 500 }}
            />
            <EditableText
                obj={vertical}
                isMarkdown={true}
                prop="description"
                style={{ fontSize: 20, maxWidth: 500 }}
            />
        </div>
    )
}

function InfoBox({ vertical }: { vertical: GetFundDto }) {
    return (
        <div
            className="col-sm"
            style={{
                backgroundColor: colors.darkGreen,
                backgroundImage: ImageToCSS(vertical.image),
                backgroundBlendMode: "soft-light",
                backgroundPosition: "center",
                backgroundSize: "cover",
                position: "relative",
                borderRadius: 16,
                color: "white",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "2rem",
                    fontSize: 20,
                }}
            >
                <div style={{ fontWeight: "bold" }}>Key Numbers</div>
                <GreenDivider />
                <div style={{ display: "flex", flexDirection: "row", marginTop: 24 }}>
                    <div style={{ flex: 1 }}>
                        <TableRow>Established:</TableRow>
                        <TableRow>Solutions:</TableRow>
                        <TableRow>ROCC:</TableRow>
                        {vertical.securities
                            .filter((s) => s.sharePrice)
                            .map((s) => (
                                <TableRow>
                                    Share price
                                    {vertical.securities.length > 1 ? " (" + s.type + ")" : ""}:
                                </TableRow>
                            ))}
                    </div>
                    <div style={{ flex: 1 }}>
                        <TableRow>
                            <EditableText obj={vertical} prop="established" defaultText="-" />
                        </TableRow>
                        <TableRow>{vertical.solutions.length}</TableRow>
                        <TableRow>
                            {thousandsSeparated.format(vertical.returnOnCarbonCapital)}
                        </TableRow>
                    </div>
                </div>
            </div>
        </div>
    )
}

function GreenDivider() {
    return (
        <div
            style={{
                backgroundColor: colors.mediumGreen,
                width: 100,
                height: 3,
                zIndex: 1,
                marginTop: 8,
                marginBottom: 8,
            }}
        />
    )
}

function NOK() {
    return <span style={{ fontSize: 12, marginLeft: 8, marginTop: 8 }}>NOK</span>
}

function TableRow(props: { children: React.ReactNode }) {
    return (
        <div style={{ display: "flex", flexDirection: "row", marginBottom: 4 }}>
            {props.children}
        </div>
    )
}

function SolutionsList(props: {
    title: string
    fundName: string
    isInPortfolio: boolean
    isAuthenticated: boolean
    solutions: GetFundSolutionsDto[] | GetFundProspectsPresentedDto[]
}) {
    const navigate = useNavigate()
    const [sort, setSort] = useState<{
        by: keyof (typeof props)["solutions"][0]
        mode: OrderByMode
    }>({ by: "returnRate", mode: "descending" })

    function toggleSort(by: keyof (typeof props)["solutions"][0]) {
        setSort({
            by,
            mode: sort.mode === "ascending" ? "descending" : "ascending",
        })
    }

    return (
        <div className="container" style={{ marginTop: 64 }}>
            <Title
                title={props.title}
                cta={
                    props.isInPortfolio
                        ? {
                              text: `See all ClimatePoint solutions`,
                              onClick: () => navigate("/solutions"),
                          }
                        : undefined
                }
            />
            <table style={{ width: "100%" }}>
                <thead style={{ borderBottom: "1px solid" }}>
                    <tr>
                        <td style={{ tableLayout: "fixed", width: 80, padding: 8 }}></td>
                        <td style={{ padding: 8 }}>
                            Name{" "}
                            <Sorter
                                active={sort.by === "name"}
                                type="alpha"
                                mode={sort.mode}
                                onClick={() => toggleSort("name")}
                            />
                        </td>
                        <td style={{ padding: 8, tableLayout: "fixed", width: 120 }}>
                            Country{" "}
                            <Sorter
                                active={sort.by === "country"}
                                type="alpha"
                                mode={sort.mode}
                                onClick={() => toggleSort("country")}
                            />
                        </td>
                        {props.isAuthenticated && (
                            <td style={{ padding: 8, tableLayout: "fixed", width: 140 }}>
                                Ownership %
                                <Sorter
                                    active={sort.by === "ownershipPercentage"}
                                    type="numeric"
                                    mode={sort.mode}
                                    onClick={() => toggleSort("ownershipPercentage")}
                                />
                            </td>
                        )}

                        {props.isAuthenticated && (
                            <td style={{ padding: 8, tableLayout: "fixed", width: 140 }}>
                                Return rate
                                <Sorter
                                    active={sort.by === "returnRate"}
                                    type="numeric"
                                    mode={sort.mode}
                                    onClick={() => toggleSort("returnRate")}
                                />
                            </td>
                        )}
                        <td
                            style={{
                                padding: 8,
                                tableLayout: "fixed",
                                minWidth: 100,
                                width: 100,
                                textAlign: "center",
                            }}
                        >
                            {"% of " + props.fundName}
                            <Sorter
                                active={sort.by === "percentageOfFund"}
                                type="numeric"
                                mode={sort.mode}
                                onClick={() => toggleSort("percentageOfFund")}
                            />
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {OrderBy(props.solutions, (s) => s[sort.by] as any, sort.mode).map((s, i) => (
                        <SolutionRow
                            s={s}
                            i={i}
                            fundName={props.fundName}
                            isAuthenticated={props.isAuthenticated}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    )
}

const returnRateFormat = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    useGrouping: true,
})

function Sorter(props: {
    active: boolean
    type: "alpha" | "numeric"
    mode: OrderByMode
    onClick: () => void
}) {
    return (
        <i
            onClick={props.onClick}
            className={`fas ${
                props.type === "alpha" ? "fa-sort-alpha-down" : "fa-sort-numeric-down"
            }${props.mode === "ascending" ? "" : "-alt"}`}
            style={{ marginLeft: 16, color: props.active ? "#666" : "#ddd", cursor: "pointer" }}
        />
    )
}

function SolutionRow({
    i,
    fundName,
    s,
    isAuthenticated,
}: {
    i: number
    fundName: string
    s: GetFundSolutionsDto | GetFundProspectsPresentedDto
    isAuthenticated: boolean
}) {
    const { hover, hoverProps, setHover } = useHover()
    const barWidth = 2
    const { ref, open } = useSolutionPopup()

    return (
        <tr
            {...hoverProps}
            onClick={open}
            style={{ cursor: "pointer", backgroundColor: i % 2 ? undefined : "#0B354011" }}
        >
            <td>
                <div style={{ position: "absolute" }}>
                    <SolutionPopup ref={ref} onClose={() => setHover(false)} solutionId={s.id} />
                </div>
                <div
                    style={{
                        tableLayout: "fixed",
                        borderRadius: 4,
                        width: 64,
                        height: 64,
                        maxWidth: 64,
                        maxHeight: 64,
                        filter: hover ? "saturate(105%) brightness(115%)" : undefined,
                        transition: "all 0.3s ease",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundImage: s.image
                            ? ImageToCSS(s.image, {
                                  width: 64,
                                  height: 64,
                              })
                            : undefined,
                    }}
                />
            </td>

            <td
                style={{
                    fontWeight: "bold",
                    color: hover ? colors.mediumGreen : colors.foreground,
                }}
            >
                {s.name}
            </td>
            <td style={{ padding: 24, justifyItems: "flex-start" }}>
                {s.flag} {s.countryCode}
            </td>
            {isAuthenticated && (
                <td style={{ padding: 24, justifyItems: "flex-start" }}>
                    {s.ownershipPercentage ? s.ownershipPercentage + " %" : ""}
                </td>
            )}
            {isAuthenticated && (
                <td>
                    {s.returnRate !== undefined && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: 1,
                                justifyContent: "flex-end",
                                maxWidth: 100 * barWidth,
                                marginRight: 32,
                            }}
                        >
                            <div style={{ textAlign: "center", marginTop: 8 }}>
                                {returnRateFormat.format(s.returnRate * 100)} %{" "}
                                <div>return rate</div>
                            </div>
                        </div>
                    )}
                </td>
            )}
            <td>
                {s.percentageOfFund !== undefined && (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                            justifyContent: "flex-end",
                            maxWidth: 100 * barWidth,
                            margin: 16,
                        }}
                    >
                        <div
                            style={{
                                width: 100 * barWidth,
                                height: 4,
                                backgroundColor: colors.subtleHover,
                            }}
                        >
                            <div
                                style={{
                                    width: s.percentageOfFund * barWidth,
                                    height: 4,
                                    backgroundColor: colors.mediumGreen,
                                }}
                            />
                        </div>
                        <div style={{ textAlign: "center", marginTop: 8 }}>
                            {returnRateFormat.format(s.percentageOfFund)} % of {fundName}
                        </div>
                    </div>
                )}
            </td>
        </tr>
    )
}
