import {
    discardLayerUpdates,
    getLayerUpdates,
    GetLayerUpdatesDto,
    mergeLayer,
    postLayerUpdates,
} from "../../studio/client"
import * as Y from "yjs"
import { YTools } from "./YTools"
import { YProxy } from "./YProxy"
import { CallEndpointWithSoftErrors } from "../../studio/Views/SoftErrorCall"
import { useEffect, useState } from "react"
import { useChangeNotifications } from "../../reactor/Web/useChangeNotification"
import { compare } from "../fast-json-patch/duplex"
import { Clone } from "../../reactor"

/**
 * State container for a Y.js document.
 *
 * Implements synchronization with the Layer API.
 */
export class YEditor<T> {
    private _yDoc: Y.Doc = new Y.Doc()

    get yDoc() {
        return this._yDoc
    }

    private _layerVersion = -1
    private _isLoading = false

    private _loaded = false
    get loaded() {
        return this._loaded
    }

    private _hasChanges = false
    get hasChanges() {
        return this._hasChanges
    }
    private set hasChanges(value: boolean) {
        this._hasChanges = value
        const hasChangesListeners = Array.from(YEditor.hasChangesListeners)
        for (const listener of hasChangesListeners) {
            listener()
        }
    }

    private _proxy: T | undefined

    /**
     * Returns an YProxy for the root document managed by this container.
     */
    get proxy(): T {
        if (!this._proxy) {
            this._proxy = YProxy(this.root)
        }
        return this._proxy as T
    }

    /**
     * The root Y.Map or Y.Array of the document.
     */
    get root(): Y.Map<any> | Y.Array<any> {
        return YTools.getRoot(this._yDoc)
    }

    private _docCache: any = undefined

    /**
     * The plain JSON representation of the document.
     *
     * When reading, this will return the JSON representation of the document.
     *
     * When writing, this will set the document to the given JSON value, by diffing the current
     * document with the new value and applying the changes.
     */
    get doc() {
        if (this.mode !== "interactive" || !this._docCache) {
            this._docCache = YTools.valueToJSON(this.root)
        }
        return this._docCache
    }
    set doc(newDoc: any) {
        this._docCache = YTools.applyDiff(this._yDoc, newDoc)
    }

    /**
     * All currently open YEditors.
     */
    static editors: YEditor<any>[] = []

    /**
     * Discard changes on all open editors.
     */
    static async discardChanges() {
        const snapshot = YEditor.editors.slice()
        for (const listener of snapshot) {
            await listener.discardChanges()
        }
    }

    /**
     * Save changes on all open editors.
     */
    static async saveChanges() {
        // Need to snapshot the set, in case the save operation modifies
        // the set of listeners.
        const snapshot = YEditor.editors.slice()
        for (const listener of snapshot) {
            await listener.saveChanges()
        }
    }

    static hasChangesListeners = new Set<() => void>()

    constructor(
        readonly res: string,
        public key: string | null,
        readonly layer: string,
        /**
         * The name of the resource type.
         *
         * For endpoints, this corresponds to the GET-endpoint's return body.
         */
        readonly typeName: string,

        /**
         * The name of the resource PUT-endpoint requestbody, if different from
         * the resource type.
         *
         * When editing the resource, only the fields in that exist in both the
         * GET- and PUT-endpoint payload will be editable.
         */
        readonly putTypeName: string | undefined,
        /**
         * Called when the root document changes.
         */
        readonly invalidate: () => void,
        /**
         * Called when the UI should be reset (e.g. after a discard).
         */
        readonly reset?: () => void
    ) {
        this._yDoc = new Y.Doc()
        YEditor.editors.push(this)
    }

    destroy() {
        this._yDoc.destroy()
        const idx = YEditor.editors.indexOf(this)
        if (idx >= 0) {
            YEditor.editors.splice(idx, 1)
        }
    }

    mode: "static" | "interactive" = "static"

    static _postOperations: Promise<any>[] = []
    static async allPostOperations() {
        if (this._postOperations.length === 0) return
        const ops = this._postOperations.slice()
        await Promise.all(ops)
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this._postOperations = this._postOperations.filter((op) => !ops.includes(op))
    }

    private resetYDoc() {
        this._yDoc.destroy()
        this._proxy = undefined
        this._yDoc = new Y.Doc()
        this._loaded = false
        this._layerVersion = -1

        if (this.mode === "interactive") {
            this._yDoc.on("update", async (data, origin) => {
                // Drop the cache on both local and remote updates
                this._docCache = undefined

                // We are currently loading the initial state, so we don't want to
                // post individual updates to the server. The load() method will
                // take care of syncing the document once loading is done.
                if (this._isLoading) return

                if (origin !== "sync") {
                    if (!this.key) {
                        throw new Error("Primary key is required for interactive mode")
                    }

                    const postOp = postLayerUpdates(this.res, this.key, this.layer, {
                        data: YTools.uint8ArrayToBase64(data),
                        clientId: useChangeNotifications.clientId,
                        version: this._layerVersion,
                    })
                    YEditor._postOperations.push(postOp)

                    const res = await postOp
                    this.handleUpdates(res)
                }
            })
        }
    }

    originalDoc: any

    /**
     * Starts an interactive session with the given document.
     *
     */
    async initInteractive(originalDoc: any) {
        this.originalDoc = Clone(originalDoc)
        this.mode = "interactive"
        this.resetYDoc()
        await this.fetchUpdates()
        this._loaded = true
        this.invalidate()
    }

    /**
     * Starts a static (view-only) session with the given document.
     *
     * This will not sync the document with the server, and will not receive
     * updates from the server.
     */
    async initStatic(newDoc: any) {
        this.originalDoc = Clone(newDoc)
        this.mode = "static"
        this._yDoc.destroy()
        this._yDoc = new Y.Doc()
        this._proxy = undefined

        this._isLoading = true
        try {
            YTools.loadDoc(this._yDoc, newDoc)
        } finally {
            this._isLoading = false
        }
        this._loaded = true
        this.invalidate()
    }

    async fetchUpdates() {
        if (!this.key) return

        await YEditor.allPostOperations()

        const res = await getLayerUpdates(this.res, this.key, this.layer, this._layerVersion)
        this.handleUpdates(res)
    }

    private handleUpdates(res: GetLayerUpdatesDto) {
        for (const update of res.ops) {
            Y.applyUpdate(this._yDoc, YTools.base64ToUint8Array(update), "sync")
        }

        const diff = compare(this.doc, this.originalDoc)
        this.hasChanges = diff.length > 0

        this._loaded = true
        this._layerVersion = Math.max(this._layerVersion, res.version)
    }

    /**
     * Saves the changes made to the document.
     *
     * Can be overwritten to provide custom behavior.
     */
    saveChanges = async (): Promise<
        { response: Awaited<ReturnType<typeof mergeLayer>> } | "cancel"
    > => {
        const res = await CallEndpointWithSoftErrors(
            "mergeLayer",
            [this.res, this.key, this.layer],
            []
        )
        if (res === "cancel") return "cancel"
        if (!res.response.isCollection) {
            // For collections, we will receive a websocket notification to
            // trigger a reload, so we don't need to do anything here.
            // For other resources, we need to fetch the updates to get the
            // latest version.
            await this.fetchUpdates()
        }
        return res
    }

    /**
     * Discards the changes made to the document.
     *
     * Can be overwritten to provide custom behavior.
     */
    discardChanges = async () => {
        if (this.key) {
            this._layerVersion = -1
            this.resetYDoc()
            await discardLayerUpdates(
                this.res,
                this.key,
                this.layer,
                useChangeNotifications.clientId
            )
            await this.initInteractive(this.originalDoc)
        }
    }
}

export function useYStaticEditor<T>(initialValue: T) {
    const [, setVersion] = useState({})
    const [editor, setEditor] = useState<YEditor<T>>(() => {
        const e = new YEditor<T>("STATIC", "STATIC", "draft", "unknown", undefined, () =>
            setVersion({})
        )
        void e.initStatic(initialValue).then(() => setEditor(e))
        return e
    })
    useEffect(() => {
        return () => {
            editor.destroy()
        }
        // On purpose not depending on anything, as we simply want the cleanup to run
        // when the component umounts
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return editor
}
