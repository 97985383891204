import { useContext } from "react"
import { Property } from "../../reactor/Types/Type"
import { DiagnosticView } from "./DiagnosticView"
import { PropRow } from "./PropRow"
import { ToolButton } from "./ToolButton"
import { ErrorContext } from "./ErrorContext"
import { useIsReadonlyField } from "./ObjectContext"
import { ToggleWidget } from "../../packages/widgets/ToggleWidget"
import { useYState, YTools } from "../../packages/y/YTools"

export function BooleanView({
    obj,
    buttons,
    property,
    label,
}: {
    obj: YTools.Node
    label?: string
    property: Property
    buttons: ToolButton[]
}) {
    const { error } = useContext(ErrorContext)
    const [value, setValue] = useYState(obj, property.name)
    const isReadonly = useIsReadonlyField(property)

    return (
        <PropRow
            isReadonly={isReadonly}
            label={label}
            buttons={buttons}
            description={property.description}
            childrenInBadge={true}
        >
            <div
                style={{
                    flex: 1,
                    backgroundColor: error ? "#f004" : undefined,
                    marginTop: 4,
                }}
            >
                {!isReadonly && (
                    <ToggleWidget
                        value={value}
                        onChanged={(v) => {
                            setValue(v)
                        }}
                    />
                )}
                {isReadonly && <div>{JSON.stringify(value)}</div>}
                <DiagnosticView value={value} property={property} />
            </div>
        </PropRow>
    )
}
