import { RButton } from "../../studio/Views/Buttons"
import { CodeEdit } from "../../studio/Views/CodeEdit"
import { PropView } from "../../studio/Views/PropView"
import { PropRow } from "../../studio/Views/PropRow"
import { LocalesProvider } from "../localization/client-side/useLocalesContext"
import { TypeScript } from "../../reactor/Types/TypeScript"
import { Localized } from "../localization/Localized"
import { useStudioTableFilterDeclarationsEndpoint } from "../../studio/client"
import { Uuid } from "../../reactor/Types/Primitives/Uuid"
import { useNavigate } from "../hooks/useNavigate"
import { useYStaticEditor } from "../y/YEditor"

export function FilterEditor(props: {
    title: string
    code?: TypeScript<any, boolean>
    description?: Localized<string>
    collectionName: string
    onCancel: () => void
    onSave: (description: Localized<string>, code: TypeScript<any, boolean>) => void
    id?: Uuid<"StudioTableFilter">
}) {
    const decls = useStudioTableFilterDeclarationsEndpoint(props.collectionName)

    const editor = useYStaticEditor({
        description: props.description ? { ...props.description } : { en: "" },
        code: props.code ?? TypeScript<any, boolean>(""),
    })
    const data = editor.proxy

    const navigate = useNavigate()

    return (
        <LocalesProvider>
            <div
                style={{
                    padding: 16,
                    backgroundColor: "white",
                    borderRadius: 16,
                    width: 600,
                    maxWidth: "100%",
                }}
            >
                <h2>{props.title}</h2>
                <PropView
                    obj={editor.root}
                    mode="inline"
                    buttons={[]}
                    property={{
                        name: "description",
                        type: {
                            alias: "Localized",
                            typeArgs: ["string"],
                            additionalProps: "string",
                            props: [],
                            tags: {
                                multiline: true,
                            },
                        },
                    }}
                />

                <PropRow buttons={[]} label="Expression">
                    <CodeEdit
                        language="typescript"
                        declarations={decls.data?.code ?? ""}
                        text={data.code.valueOf()}
                        onEdited={(text) => (data.code = text as any as TypeScript<any, boolean>)}
                    />
                </PropRow>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <RButton onClick={() => props.onCancel()}>Cancel</RButton>
                    <RButton
                        variant="primary"
                        onClick={() => props.onSave(data.description, data.code)}
                    >
                        Save
                    </RButton>
                    <div style={{ flex: 1 }} />
                    {props.id ? (
                        <RButton
                            onClick={() => {
                                if (
                                    props.code !== data.code ||
                                    JSON.stringify(props.description) !==
                                        JSON.stringify(data.description)
                                ) {
                                    if (
                                        !confirm(
                                            "You have unsaved changes. Are you sure you want to discard them?"
                                        )
                                    ) {
                                        return
                                    }
                                }
                                props.onCancel()
                                navigate(`/studio/studiotablefilters/${props.id}`)
                            }}
                        >
                            Sharing settings
                        </RButton>
                    ) : null}
                </div>
            </div>
        </LocalesProvider>
    )
}
