import * as Y from "yjs"
import { YTools } from "../packages/y/YTools"
import { GetTypeProps, IsArrayType, Type } from "./Types/Type"

/** Represents a path from a root object down to a subgraph or value. */
export type ObjectPath = (string | number)[]

/** Resolves what the provided object path points too within the root. */
export function ResolveObjectPath(root: YTools.Node, path: ObjectPath): unknown {
    for (const segment of path) {
        if (root && (root instanceof Y.Array || root instanceof Y.Map)) {
            root =
                root instanceof Y.Array
                    ? root.get(typeof segment === "string" ? parseInt(segment) : segment)
                    : root.get(typeof segment === "number" ? segment.toString() : segment)
        }
    }
    return root
}

/** Resolves the type of what the provided object path points too within the root. */
export function ResolveObjectPathType(
    type: Type,
    root: YTools.Node,
    path: ObjectPath
): Type | undefined {
    for (const segment of path) {
        if (IsArrayType(type)) {
            type = type.array
        } else {
            const props = GetTypeProps(type, root)
            const prop = props.find((p) => p.name === segment)
            if (!prop?.type) return undefined
            type = prop?.type
        }

        if (root && typeof root === "object") {
            root = YTools.get(root, segment)
        }
    }
    return type
}

export function ObjectPathsEqual(a: ObjectPath, b: ObjectPath) {
    return a.length === b.length && a.every((x, i) => b[i] === x)
}
