import { useEffect, useState } from "react"

/**
 * A hook that returns whether any of the active YEditors has changes to save or
 * discard.
 *
 * If we are not in edit mode, this will not load Y.js.
 */
export function useYHasChanges(editing: boolean) {
    const [hasChanges, setHasChanges] = useState(false)

    useEffect(() => {
        if (!editing) return

        let onChange: (() => void) | undefined
        let YEditor: typeof import("./YEditor").YEditor | undefined

        async function observe() {
            YEditor = (await import("./YEditor")).YEditor
            onChange = () => {
                setHasChanges(YEditor!.editors.some((editor) => editor.hasChanges))
            }

            YEditor.hasChangesListeners.add(onChange)

            onChange()
        }

        void observe()
        return () => {
            if (onChange && YEditor) {
                YEditor.hasChangesListeners.delete(onChange)
            }
        }
    }, [editing])

    return hasChanges
}
