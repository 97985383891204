import { useState } from "react"
import { Property } from "../../reactor/Types/Type"
import { useDocumentPropertyDeclarations } from "../client"
import { RButton } from "./Buttons"
import { CodeEdit } from "./CodeEdit"
import { PropRow } from "./PropRow"
import { useDocument } from "./DocumentContext"
import { useYState, YTools } from "../../packages/y/YTools"

export function TypeScriptView({
    obj,
    property,
    label,
    mode,
}: {
    obj: YTools.Node
    label?: string
    property: Property
    mode?: "json-stringify"
}) {
    const [editing, setEditing] = useState(false)

    const value = YTools.get(obj, property.name)

    return (
        <PropRow
            buttons={[]}
            badge={
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <RButton
                        variant="secondary"
                        icon={editing ? "check" : "pencil"}
                        onClick={() => setEditing(!editing)}
                    >
                        {editing ? "Done" : "Edit"}
                    </RButton>
                </div>
            }
            description={property.description}
            label={label}
        >
            {editing ? (
                <TypeScriptViewInner obj={obj} property={property} mode={mode} />
            ) : (
                <pre style={{ backgroundColor: "#f8f8f8", padding: 16 }}>
                    {mode === "json-stringify" ? JSON.stringify(value, null, 2) : value}
                </pre>
            )}
        </PropRow>
    )
}

function TypeScriptViewInner({
    obj,
    property,
    mode,
}: {
    obj: YTools.Node
    property: Property
    mode?: "json-stringify"
}) {
    const declarationsFunc = property.tags?.declarations
    const doc = useDocument()
    const [value, setValue] = useYState(obj, property.name)

    const { data: declarations } = useDocumentPropertyDeclarations(
        !!declarationsFunc
            ? {
                  document: doc,
                  object: obj !== doc ? obj : null,
                  declarationsFunc,
                  includeMutations: property.tags?.includeMutations,
              }
            : null
    )
    return (
        <CodeEdit
            text={mode === "json-stringify" ? JSON.stringify(value, null, 2) : value}
            declarations={declarations?.code || ""}
            language={mode === "json-stringify" ? "json" : "typescript"}
            onEdited={(code) => {
                if (mode === "json-stringify") {
                    try {
                        setValue(JSON.parse(code))
                    } catch (e: any) {
                        alert(e.message)
                        return
                    }
                } else {
                    setValue(code)
                }
            }}
        />
    )
}
