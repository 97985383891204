import { Chart, ChartConfiguration } from "chart.js"
import { useEffect, useRef } from "react"
import { useIsEditing } from "../../../../packages/editing/useIsEditing"
import { EditableText } from "../../../../packages/editing/EditableText"
import { GetType } from "../../../../reactor/ReflectionInfo"
import { GetAccountDto } from "../client"
import { ChartCommon, ChartValue } from "./ChartCommon"
import { useSelectionContext } from "../../../../packages/editing/SelectionContext"

export function GetChartDataset(section: ChartCommon, account: GetAccountDto | null) {
    let unit = section.unit
    let values = section.values.slice()
    if (account && section.dataset === "Investments by SDG") {
        values = (account.investmentBySdgPercent ?? []).concat(...(values as any)) as any
        unit = "%"
    }

    const minValue = section.minimumValue
    if (minValue !== undefined) {
        const tooSmallValues = values.filter((v) => v.value < minValue)
        values = values.filter((v) => v.value >= minValue)
        if (tooSmallValues.length && tooSmallValues.reduce((a, b) => a + b.value, 0) > minValue) {
            values.push({
                label: "Other",
                brandColor: "darkGreen",
                value: tooSmallValues.reduce((a, b) => a + b.value, 0),
            })
        }
    }
    values.sort((a, b) => b.value - a.value)

    return { unit, values }
}

export function ChartBase({
    config,
    section,
}: {
    config: ChartConfiguration<any>
    section: { title: string; values: ChartValue[] }
}) {
    const { setSelectedObject } = useSelectionContext()
    const editing = useIsEditing()
    const ref = useRef<HTMLCanvasElement>(null)
    const chartRef = useRef<Chart | null>(null)

    config.options ??= {}
    config.options.onClick = (event: any) => {
        if (editing) {
            const points: any = chartRef.current?.getElementsAtEventForMode(
                event,
                "nearest",
                { intersect: true },
                true
            )
            if (points.length === 1) {
                if ("index" in points[0]) {
                    const index = points[0].index
                    setSelectedObject(section.values[index], GetType("ChartValue"))
                }
            }
        }
    }
    useEffect(() => {
        if (ref.current) {
            const ctx = ref.current.getContext("2d")
            if (!ctx) return

            chartRef.current = new Chart(ctx, config)
        }
        return () => chartRef.current?.destroy()
    }, [ref.current])

    useEffect(() => {
        if (chartRef.current) {
            const dataset = chartRef.current.data.datasets[0]
            const newDataset = config.data.datasets[0]

            for (let i = 0; i < newDataset.data.length; i++) {
                dataset.data[i] = newDataset.data[i]
                ;(dataset.backgroundColor as any)[i] = newDataset.backgroundColor[i]
            }
            chartRef.current.data.labels = config.data.labels

            chartRef.current.update("active")
        }
    }, [JSON.stringify(config)])

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                justifyItems: "center",
                alignItems: "center",
                width: 532,
                marginTop: 64,
            }}
        >
            <h4 style={{ marginBottom: 32 }}>
                <EditableText obj={section} prop="title" />
            </h4>

            <canvas ref={ref} style={{ width: "100%" }} />
        </div>
    )
}
