import { ColorStyles } from "../../packages/ui"
import { useYState } from "../../packages/y/YTools"
import { Property } from "../../reactor/Types/Type"
import { RButton } from "./Buttons"
import { PropRow } from "./PropRow"
import { PropView } from "./PropView"
import { ToolButton } from "./ToolButton"

export function RecordView({
    label,
    buttons,
    property,
    obj,
}: {
    label?: string
    buttons: ToolButton[]
    obj: any
    property: Property
}) {
    const [value, setValue] = useYState(obj, property.name)
    if (value === null || typeof value !== "object") {
        return (
            <PropRow label={label} buttons={buttons} description={property.description}>
                <RButton
                    onClick={() => {
                        setValue({})
                    }}
                >
                    Specify
                </RButton>
            </PropRow>
        )
    }
    const type = property.type
    if (typeof type !== "object") {
        throw new Error("Expected object type")
    }
    const typeArgs = type.typeArgs
    if (!typeArgs) {
        throw new Error("Expected typeArgs")
    }

    const keys = Object.keys(value)

    const [keyType, valueType] = typeArgs

    return (
        <PropRow label={label} buttons={buttons} description={property.description}>
            <div
                style={{
                    padding: 12,
                    border: "1px solid " + ColorStyles.gray[200],
                    borderRadius: 8,
                }}
            >
                {keys.map((key) => (
                    <div style={{ marginBottom: 16 }}>
                        <PropView
                            key={key}
                            obj={value}
                            label={key}
                            property={{ name: key, type: valueType }}
                            isEmbedded={true}
                            mode="inline"
                            buttons={[
                                {
                                    text: "Delete",
                                    onClick: () => {
                                        delete value[key]
                                        setValue({ ...value })
                                    },
                                },
                            ]}
                        />
                    </div>
                ))}
                <RButton
                    icon="ui-plus"
                    variant="secondary"
                    onClick={() => {
                        const newKey = prompt("Enter the key")
                        if (newKey) {
                            value[newKey] = null
                            setValue({ ...value })
                        }
                    }}
                >
                    Add
                </RButton>
            </div>
        </PropRow>
    )
}
