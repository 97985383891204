import { colors, fontSizes } from "../styles"
import { useClickableCard } from "../useClickableCard"
import { Grid } from "../Grid"
import { Fund, useVerticals } from "../client"
import { useNavigate } from "../../../../packages/hooks/useNavigate"
import { EditableText } from "../../../../packages/editing/EditableText"
import { EditableArray } from "../../../../packages/editing/EditableArray"
import { EditableImage } from "../../../../packages/editing/EditableImage"
import { useIsEditing } from "../../../../packages/editing/useIsEditing"
import { useAppearAnimation } from "../../../../packages/appear-animation/useAppear"
import { Section } from "../../../../packages/editing/Section"

Section(VerticalsSection)
function VerticalsSection(section: { title: string }) {
    const { data: verticals } = useVerticals()
    const { ref, style } = useAppearAnimation()

    return (
        <div className="container" ref={ref} style={{ ...style, marginTop: 64 }}>
            <EditableText
                obj={section}
                prop="title"
                style={{
                    fontSize: 32,
                    fontWeight: "bold",
                    color: "#0B3540",
                    marginBottom: 32,
                    textAlign: "center",
                    width: "100%",
                }}
            />

            <Grid>
                {verticals && (
                    <EditableArray arr={verticals} direction="row" itemTypeName="Fund">
                        {(f, i) => <FundView index={i} key={f.id.toString()} fund={f} />}
                    </EditableArray>
                )}
            </Grid>
        </div>
    )
}

function FundView({ fund, index }: { fund: Fund; index: number }) {
    const { ref, style } = useAppearAnimation({
        delayMs: 100 + index * 100,
        durationMs: 300,
        transform: "translateX(-50px)",
    })
    const navigate = useNavigate()
    const { props, hover } = useClickableCard(style)
    const editing = useIsEditing()

    return (
        <div
            onClick={() => !editing && navigate("/funds/" + fund.slug)}
            {...props}
            ref={ref}
            style={{
                display: "flex",

                flexDirection: "column",
                backgroundColor: colors.white,
                color: colors.foreground,
                marginBottom: 48,
                fontSize: fontSizes.body,
                position: "relative",
                ...props.style,
            }}
        >
            <EditableImage
                obj={fund}
                prop="image"
                options={{ width: 320 }}
                style={{
                    borderRadius: 16,
                    backgroundSize: "cover",
                    minHeight: 220,
                    backgroundPosition: "center",
                }}
            >
                <div
                    style={{
                        backgroundColor: hover ? colors.overlayGreenHover : colors.overlayGreen,
                        transition: "all 0.3s ease",
                        borderRadius: 16,
                        width: "100%",
                        height: 220,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <EditableText
                        obj={fund}
                        prop="name"
                        style={{
                            fontSize: 24,
                            fontWeight: "bold",
                            textShadow: "2px 2px 1 #000",
                            color: "white",
                        }}
                    />
                    <GreenDivider />
                </div>
            </EditableImage>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    paddingTop: 16,
                }}
            >
                <div
                    style={{
                        flex: 1,
                        fontSize: fontSizes.body,
                    }}
                >
                    <EditableText obj={fund} prop="summary" isMarkdown={true} />
                </div>
            </div>
        </div>
    )
}

function GreenDivider() {
    return (
        <div
            style={{
                backgroundColor: colors.mediumGreen,
                width: 100,
                height: 3,
                zIndex: 1,
                marginTop: 8,
                marginBottom: 8,
            }}
        />
    )
}
