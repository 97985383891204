import { Type } from "../../reactor"
import { createContext, useContext } from "react"
import type {
    StudioFileInfo,
    StudioPropOptions,
    StudioPropWidget,
    StudioRefTypes,
    StudioTypeExtensions,
} from "../API/DocumentPageAPI"
import * as Y from "yjs"
import { YTools } from "../../packages/y/YTools"

export const DocumentRefreshContext = createContext<(() => Promise<void>) | undefined>(undefined)

/**
 * Returns a function that will refresh the metadata for the current document.
 *
 * This keeps local changes to the document, and updates the metadata
 * based on the local version of the document.
 */
export function useDocumentRefresh() {
    return useContext(DocumentRefreshContext)
}

export const ShowAllPropsContext = createContext<boolean>(true)

/**
 * Returns whether to display all props in this context.
 *
 * In some editors, there is a "Show All" toggle that is used to control this.
 * In contexts without this toggle, this will always return true.
 */
export function useShowAllProps() {
    return useContext(ShowAllPropsContext)
}

export const ToggleShowAllPropsContext = createContext<(() => void) | undefined>(undefined)

/**
 * Returns a function that will toggle whether to show all props in this context.
 */
export function useToggleShowAllProps() {
    return useContext(ToggleShowAllPropsContext)
}

export const DocumentContext = createContext<YTools.Node | undefined>(undefined)

/**
 * Returns the current document being displayed or edited in this context.
 *
 * In Studio, this is typically the current document being displayed, but it can
 * also e.g. be the arguments to a POST request (in PostView). Editors consuming this
 * should be agnostic to the exact type of document being edited.
 *
 * If no document is available, this will return `undefined`.
 */
export function useDocument() {
    return useContext(DocumentContext)
}

export const DocumentTypeContext = createContext<Type | undefined>(undefined)

/**
 * Returns the type of the current document being displayed or edited in this
 * context, if meaningful or available.
 */
export function useDocumentType(): Type | undefined {
    return useContext(DocumentTypeContext)!
}

export const DocumentFilesContext = createContext<StudioFileInfo | undefined>(undefined)

/**
 * Returns metadata about the files referenced by the current document, if available.
 */
export function useDocumentFiles(): StudioFileInfo | undefined {
    return useContext(DocumentFilesContext)
}

export const DocumentTypeExtensionsContext = createContext<StudioTypeExtensions | undefined>(
    undefined
)

/**
 * Returns type extensions for the current document, if available.
 */
export function useDocumentTypeExtensions(): StudioTypeExtensions | undefined {
    return useContext(DocumentTypeExtensionsContext)
}

export const DocumentWidgetsContext = createContext<StudioPropWidget | undefined>(undefined)

/**
 * Returns widget representations for the current document, if available.
 */
export function useDocumentWidgets(): StudioPropWidget | undefined {
    return useContext(DocumentWidgetsContext)
}

export const DocumentRefsContext = createContext<StudioRefTypes | undefined>(undefined)

/**
 * Returns references for the current document, if available.
 */
export function useDocumentRefs(): StudioRefTypes | undefined {
    return useContext(DocumentRefsContext)
}

export const DocumentPrimaryKeyContext = createContext<string | undefined>(undefined)

/**
 * Returns the primary key of the current document, if available.
 */
export function useDocumentPrimaryKey(): string | undefined {
    return useContext(DocumentPrimaryKeyContext)
}

export const DocumentCollectionContext = createContext<string | undefined>(undefined)

/**
 * Returns the collection of the current document, if available.
 */
export function useDocumentCollection(): string | undefined {
    return useContext(DocumentCollectionContext)
}

export const DocumentOptionsContext = createContext<StudioPropOptions | undefined>(undefined)

/**
 * Returns options for the current document, if available.
 */
export function useDocumentOptions(): StudioPropOptions | undefined {
    return useContext(DocumentOptionsContext)
}

export const DocumentCanUpdateContext = createContext<boolean | string[] | undefined>(undefined)

/**
 * Returns whether the current document can be updated by the current user, i.e.
 * whether they have update permission.
 *
 * Returns:
 * - `true` if the user has general update permission
 * - An array of field names the user may edit, if the user only has permission to update certain fields
 * - `false` if they don't have permission
 * - `undefined` if the permission state is unknown/irrelevant
 */
export function useDocumentCanUpdate() {
    return useContext(DocumentCanUpdateContext)
}

/**
 * Provides all the different contexts for a document. Each value is its own
 * context, so that consumers can subscribe to only the values they need.
 */
export function DocumentContextProvider(props: {
    doc: Y.Map<any> | Y.Array<any>
    type: Type
    widgets?: StudioPropWidget
    primaryKey: string
    collection: string
    options?: StudioPropOptions
    refs?: StudioRefTypes
    files?: StudioFileInfo
    typeExtensions?: StudioTypeExtensions
    children: React.ReactNode
    showAllProps: boolean
    canUpdate?: boolean | string[]
    toggleShowAllProps?: () => void
    refresh?: () => Promise<void>
}) {
    return (
        <DocumentContext.Provider value={props.doc}>
            <DocumentWidgetsContext.Provider value={props.widgets}>
                <DocumentPrimaryKeyContext.Provider value={props.primaryKey}>
                    <DocumentCollectionContext.Provider value={props.collection}>
                        <DocumentOptionsContext.Provider value={props.options}>
                            <DocumentRefsContext.Provider value={props.refs}>
                                <DocumentFilesContext.Provider value={props.files}>
                                    <DocumentTypeExtensionsContext.Provider
                                        value={props.typeExtensions}
                                    >
                                        <ToggleShowAllPropsContext.Provider
                                            value={props.toggleShowAllProps}
                                        >
                                            <ShowAllPropsContext.Provider
                                                value={props.showAllProps}
                                            >
                                                <DocumentRefreshContext.Provider
                                                    value={props.refresh}
                                                >
                                                    <DocumentTypeContext.Provider
                                                        value={props.type}
                                                    >
                                                        <DocumentCanUpdateContext.Provider
                                                            value={props.canUpdate}
                                                        >
                                                            {props.children}
                                                        </DocumentCanUpdateContext.Provider>
                                                    </DocumentTypeContext.Provider>
                                                </DocumentRefreshContext.Provider>
                                            </ShowAllPropsContext.Provider>
                                        </ToggleShowAllPropsContext.Provider>
                                    </DocumentTypeExtensionsContext.Provider>
                                </DocumentFilesContext.Provider>
                            </DocumentRefsContext.Provider>
                        </DocumentOptionsContext.Provider>
                    </DocumentCollectionContext.Provider>
                </DocumentPrimaryKeyContext.Provider>
            </DocumentWidgetsContext.Provider>
        </DocumentContext.Provider>
    )
}
