/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { IsNode } from "../../reactor/AssertNode"
import { ProblemJson, ProblemJsonHandler, ProblemJsonHandlerResult } from "./ProblemJson"
import type { YEditor } from "../y/YEditor"
import { useChangeNotifications } from "../../reactor/Web/useChangeNotification"
import { EditableResource } from "../editing/Ancestry"
import { IsEditingContext } from "../editing/useIsEditing"
import { SelectionContext } from "../editing/SelectionContext"
import { ResourceNotificationType } from "../../reactor/Server/ResourceNotification"

export type SSRResource<T> = {
    /** The current data, potentially with uncommitted client-side edits. */
    data: T | undefined
    /** The original data received from the getter. */
    originalData: T | undefined
    loading: boolean
    error?: ProblemJson | undefined
    /** If this resource is waiting for one or more of its input parameters to become ready, this
     * holds the list of parameters it is waiting for. Passing `null` to the hook indicates a
     * missing parameter. */
    waitingForInputs?: string[]

    /** Refreshes the resource.
     *
     *  If this is a shared resource, this will invalidate all consumers. */
    refresh(): Promise<void>
}
export type SSRHookListener<TArgs extends any[], TReturn> = (data: TReturn, args: TArgs) => void

export type SSRHook<TArgs extends any[], TReturn> = {
    (...args: TArgs): SSRResource<TReturn>
    /** Registers a listener that will receive a callback when new data has been fetched by this
     *  hook.
     *
     *  This can be useful if you want to display some data from this endpoint without having to
     *  call it multiple times.
     */
    addDataListener(listener: SSRHookListener<TArgs, TReturn>): void
    removeDataListener(listener: SSRHookListener<TArgs, TReturn>): void
}

/** Returns the data from a SSR client endpoint. This does not actually call the endpoint, but
 * observes if the endpoint is called from elsewhere in the code base.
 *
 *  This is useful e.g. if creating a component that displays data which is primarily managed by a
 *  different component.
 *
 *  Example:
 *  ```
 *  const searchResults = useDataFrom(useGlobalSearch)
 *  ```
 *
 *  In the above example, the `searchResults` will be provided if `postGlobalSearch` is used
 *  elsewhere in the code base.
 */
export function useDataFrom<TArgs extends any[], TReturn>(hook: SSRHook<TArgs, TReturn>) {
    const [data, setData] = useState<{ args: TArgs; response: TReturn } | undefined>(undefined)
    useEffect(() => {
        const listener = (response: TReturn, args: TArgs) => {
            setData({ args, response })
        }
        hook.addDataListener(listener)
        return () => hook.removeDataListener(listener)
    }, [hook])
    return data
}

;(globalThis as any).ssrEndpoints = IsNode() ? {} : null

/** Creates a React hook that wraps an GET-endpoint on the server in a SSR-aware fashion.
 *
 *  By convention, if any of the arguments to the endpoint is `null`, this indicates a missing
 *  request parameter. The hook will wait for the parameter to be available before calling the
 *  endpoint. This is a hack, since the signature of the arguments does not include `null`.
 *
 */
export function SSRHook<TArgs extends any[], TReturn>(
    /** Name of the server side getter-function this hook maps to */
    funcName: string,
    /** Name of the DTO type that this hook GETs. */
    dtoName: string | undefined,
    /** Name of the DTO type that this hook PUTs.
     *
     *  This limits what properties from the GET DTO will be displayed as
     *  editable in WYSIWYG editors. Only properties that are present in both
     *  the PUT and GET DTOs will be editable.
     */
    putDtoName: string | undefined,
    /** The client side getter function */
    getter: (...args: any[]) => Promise<TReturn>,
    /** The names of the arguments to the getter function. */
    argNames: string[],
    /** A function that saves the resource back to its origin. If provided, the resource will be
     * editable. */
    putter?: (args: any[], body: TReturn) => Promise<void>
): SSRHook<TArgs, TReturn> {
    const listeners: SSRHookListener<TArgs, TReturn>[] = []

    /** Returns the inputs that we are still waiting for in order for the hook
     *  to be able to resolve its data.
     *
     *  If `null` is passed for any of the arguments, this indicates the hook is
     *  waiting for an input to become available and should not evaluate yet. We
     *  still need to call all of the React hooks to get the same number of
     *  hooks per render.
     **/
    function getWaitingForInputs(args: TArgs) {
        return args
            .map((a, i) => i)
            .filter((i) => args[i] === null)
            .map((i) => argNames[i])
    }

    /** Returns the SSR results on the client side */
    function clientSideResolve(query: string) {
        return {
            data: (("ssrResults" in globalThis && ssrResults && ssrResults[query]?.result) ||
                undefined) as TReturn | undefined,
            needsAuthenticatedRefresh:
                "ssrResults" in globalThis &&
                ssrResults &&
                ssrResults[query]?.needsAuthenticatedRefresh
                    ? true
                    : false,
            error: (("ssrResults" in globalThis && ssrResults && ssrResults[query]?.error) ||
                undefined) as ProblemJson | undefined,
        }
    }

    /** Resolves the SSR results on the server side. On the client side, this
     * returns `undefined`. */
    function serverSideResolve(waitingForInputs: string[], args: TArgs) {
        if (ssrEndpoints && !(funcName in ssrEndpoints)) {
            needsAuthenticatedRefresh = true
            // Not an SSR-compatible endpoint, try again when authenticated
            return {
                data: undefined,
                originalData: undefined,
                error: {
                    type: "Unauthorized",
                    status: 401,
                },
                loading: false,
                needsAuthenticatedRefresh: true,
                // Dummy, refresh not possible in SSR mode
                refresh: () => Promise.resolve(),
            }
        }

        if (ssrEndpoints && ssrEndpoints[funcName] && ssrTransaction && ssrTransaction.current) {
            const query = JSON.stringify({ name: funcName, args })

            if (waitingForInputs.length) {
                return {
                    waitingForInputs,
                    data: undefined,
                    loading: false,
                    originalData: undefined,
                    refresh: async () => {},
                }
            }

            const existingError =
                query in ssrTransaction.current ? ssrTransaction.current[query].error : undefined

            if (existingError) {
                return {
                    data: undefined,
                    originalData: undefined,
                    error: existingError,
                    loading: false,
                    // Dummy, refresh not possible in SSR mode
                    refresh: () => Promise.resolve(),
                }
            }

            const existingPromise =
                query in ssrTransaction.current ? ssrTransaction.current[query] : undefined

            const existingResult = existingPromise?.result

            if (existingResult) {
                return {
                    data: existingResult as TReturn,
                    originalData: existingResult as TReturn,
                    loading: false,
                    // Dummy, refresh not possible in SSR mode
                    refresh: () => Promise.resolve(),
                }
            }

            if (existingPromise) {
                return {
                    data: undefined,
                    originalData: undefined,
                    loading: true,
                    // Dummy, refresh not possible in SSR mode
                    refresh: () => Promise.resolve(),
                }
            }

            try {
                needsAuthenticatedRefresh = false
                const promise = ssrEndpoints[funcName](...args)
                let synchronousCrash: any
                if (typeof promise === "object" && "catch" in promise) {
                    // We got an asynchronous result - wait for it
                    promise.catch((e: any) => {
                        synchronousCrash = e
                    })
                    ssrTransaction.current[query] = {
                        promise,
                        needsAuthenticatedRefresh: needsAuthenticatedRefresh ? true : undefined,
                    }
                } else {
                    // We got a synchronous result - hooraay!
                    ssrTransaction.current[query] = {
                        result: promise,
                        needsAuthenticatedRefresh: needsAuthenticatedRefresh ? true : undefined,
                    }

                    return {
                        data: promise as TReturn,
                        originalData: promise as TReturn,
                        loading: false,
                        // Dummy, refresh not possible in SSR mode
                        refresh: () => Promise.resolve(),
                    }
                }

                if (synchronousCrash) {
                    return {
                        data: undefined,
                        originalData: undefined,
                        error: synchronousCrash,
                        loading: false,
                        // Dummy, refresh not possible in SSR mode
                        refresh: () => Promise.resolve(),
                    }
                }

                return {
                    data: undefined,
                    originalData: undefined,
                    loading: true,
                    // Dummy, refresh not possible in SSR mode
                    refresh: () => Promise.resolve(),
                }
            } catch (e: any) {
                // Failed synchronously
                return {
                    data: undefined,
                    originalData: undefined,
                    error: e,
                    loading: false,
                    // Dummy, refresh not possible in SSR mode
                    refresh: () => Promise.resolve(),
                }
            }
        }
    }

    function hook(...args: TArgs): SSRResource<TReturn> {
        const waitingForInputs = getWaitingForInputs(args)

        // Shortcut for fetching data on the server as part of SSR
        const serverSideResult = serverSideResolve(waitingForInputs, args)
        if (serverSideResult) return serverSideResult

        const query = useMemo(() => JSON.stringify({ name: funcName, args }), [funcName, ...args])

        const ssr = clientSideResolve(query)
        const firstRender = useRef(true)

        const [version, setVersion] = useState({})
        const [originalData, setData] = React.useState<TReturn | undefined>(ssr.data || undefined)
        const [error, setError] = React.useState<ProblemJson | undefined>(ssr.error || undefined)
        const [loading, setLoading] = React.useState(originalData === undefined)

        if (error) {
            console.error(`Error while fetching ${funcName}(${args.join(", ")}):`, error)
        }

        const [yEditor, setYEditor] = useState<YEditor<TReturn> | undefined>(undefined)

        const currentResource: EditableResource = {
            query,
            endpoint: funcName,
            data: yEditor ? yEditor.proxy : originalData,
            dtoName: dtoName ?? "unknown",
            putDtoName: putDtoName!,
        }
        const resource = useRef<EditableResource>(currentResource)
        Object.assign(resource.current, currentResource)

        const isEditing = useContext(IsEditingContext)

        const refresh = React.useCallback(async () => {
            if (waitingForInputs.length) return
            setLoading(true)
            try {
                const d = await getter(...args)
                listeners.forEach((l) => l(d, args))
                setData(d)
                setError(undefined)
                setLoading(false)
            } catch (e: any) {
                // No need to handle errors or retry here, since this is handled
                // internally in the `getter` function.

                const problem: ProblemJson =
                    typeof e === "object" && "type" in e ? e : { type: "Unknown", error: e }

                setError(problem)
                setLoading(false)
            }
        }, [query])

        const onChange = React.useCallback(
            async (type: ResourceNotificationType) => {
                if (type === "change") await yEditor?.fetchUpdates()
                if (type === "discard" || type === "save") await refresh()
            },
            [yEditor, refresh]
        )

        const selectionContext = useContext(SelectionContext)

        if (putter) {
            const argList = JSON.stringify(args)
            useChangeNotifications(funcName, argList, "draft", onChange, isEditing)

            React.useEffect(() => {
                if (waitingForInputs.length) return

                function invalidate() {
                    setVersion({})
                }
                function reset() {
                    // In case the selection was inside the editor that was
                    // reset, we need to clear it
                    selectionContext?.clearSelectedObject()
                }

                let ye: YEditor<TReturn> | undefined
                async function initEditor() {
                    if (!originalData) return
                    if (isEditing) {
                        const { YEditor } = await import("../y/YEditor")
                        ye = new YEditor<TReturn>(
                            funcName,
                            argList,
                            "draft",
                            dtoName ?? "unknown",
                            putDtoName,
                            invalidate,
                            reset
                        )
                        const originalDiscard = ye.discardChanges
                        ye.discardChanges = async () => {
                            await originalDiscard()
                            reset()
                        }

                        await ye.initInteractive(originalData)
                        setYEditor(ye)
                    } else {
                        yEditor?.destroy()
                        ye = undefined
                        setYEditor(undefined)
                    }
                }
                if (isEditing) void initEditor()

                return () => {
                    ye?.destroy()
                    ye = undefined
                }
            }, [originalData, isEditing, argList, funcName, waitingForInputs.length])
        }

        React.useEffect(() => {
            if ((ssr.error || ssr.data) && firstRender.current) {
                // No need to fetch, we have SSR data
                firstRender.current = false

                if (ssr.needsAuthenticatedRefresh) {
                    // The SSR data needs to be refreshed with an authenticated request
                    void refresh()
                }
                return
            }
            void refresh()
        }, [query])

        if (waitingForInputs.length) {
            // While waiting for inputs, we should not display any data, even if
            // we have some data cached from a previously valid set of inputs.
            // Displaying cached data that are not based on the current inputs
            // is potentially erroneous and hazardous, since the state being
            // displayed would now be inconsistent. This could mislead the user
            // into making wrong actions in the UI.
            //
            // This manifested in bugs like #1533.
            //
            return {
                data: undefined, // Should be undefined - see above
                originalData: undefined, // Should be undefined - see above
                error,
                loading: false,
                waitingForInputs,
                refresh,
            }
        }

        const waitingForAuthenticatedRefresh = ssr.needsAuthenticatedRefresh && firstRender.current

        return {
            data: yEditor?.proxy ?? originalData,
            originalData,
            error: waitingForAuthenticatedRefresh ? undefined : error,
            loading: waitingForAuthenticatedRefresh || loading,
            refresh,
        }
    }

    hook.addDataListener = (listener: SSRHookListener<TArgs, TReturn>) => {
        listeners.push(listener)
    }
    hook.removeDataListener = (listener: SSRHookListener<TArgs, TReturn>) => {
        const index = listeners.indexOf(listener)
        if (index >= 0) listeners.splice(index, 1)
    }

    return hook
}

let needsAuthenticatedRefresh = false
/** If called from an endpoint, it indicates that an endpoint that was read
 * during SSR will require an authenticated refresh from the client side to
 * produce the correct result. This allows the page to render the rest of its
 * content (that does not require authentication.)  */
SSRHook.requireAuthenticatedRefresh = function () {
    needsAuthenticatedRefresh = true
}
SSRHook.errorHandlers = [] as ProblemJsonHandler[]

/** Handles an error from a failed fetch(). This is used by generated clients.
 *
 *  This will invoke the registered error handlers in turn to see if any of them can handle the
 *  error. If so, the error may trigger some side effect, such as refreshing the access token.
 *
 *
 *
 *  Regardless of whether the error is handled or not, it will typically throw the error, but may
 *  also (in the future) return a valid response provided by one of the handlers.
 */
SSRHook.handleError = async (res: Response): Promise<ProblemJsonHandlerResult> => {
    const contentType = res.headers.get("Content-Type")
    if (contentType?.includes("application/problem+json")) {
        const problem = await res.json()

        for (const handler of SSRHook.errorHandlers) {
            const handledResponse = await handler(problem)
            if (handledResponse.retry) return handledResponse
            if (handledResponse.handled) break
        }
        throw problem
    }
    throw new Error("Unhandled fetch error")
}

/** A registry of all the generated client endpoints. */
SSRHook.clientEndpoints = [] as {
    /** The name of the function.
     *
     *  Since this is used on the client, the names are scrambled in production
     *  builds, so we need to keep the original name around.
     */
    name: string
    func: Function
}[]
